import { generateCodeChallenge, generateRandomString } from './pkceUtils'
import { TInternalConfig, TAzureADErrorResponse, TTokenResponse, TRevokeResponse } from './Types'

const codeVerifierStorageKey = 'PKCE_code_verifier'

export const EXPIRED_REFRESH_TOKEN_ERROR_CODES = ['AADSTS700084']

export async function logIn (config: TInternalConfig) {
  // Create and store a random string in localStorage, used as the 'code_verifier'
  const codeVerifier = generateRandomString(50)
  localStorage.setItem(codeVerifierStorageKey, codeVerifier)

  // Hash and Base64URL encode the code_verifier, used as the 'code_challenge'
  generateCodeChallenge(codeVerifier).then((codeChallenge) => {
    // Set query parameters and redirect user to OAuth2 authentication endpoint
    const params = new URLSearchParams({
      response_type: 'code',
      client_id: config.clientId,
      scope: config.scope,
      redirect_uri: config.redirectUri,
      code_challenge: codeChallenge,
      code_challenge_method: 'S256'
    })
    // Call any preLogin function in authConfig
    if (config?.preLogin != null) config.preLogin()
    window.location.replace(`${config.authorizationEndpoint}?${params.toString()}`)
  })
}

// This is called a "type predicate". Which allow use to know which kind of response we got, in a type safe way.
function isTokenResponse (body: TAzureADErrorResponse | TTokenResponse | TRevokeResponse): body is TTokenResponse {
  return (body as TTokenResponse).access_token !== undefined
}

function isRevokeResponse (body: TAzureADErrorResponse | TRevokeResponse): body is TRevokeResponse {
  return Object.keys(body as TRevokeResponse).length === 0
}

async function postWithFormData (tokenEndpoint: string, formData: FormData): Promise<TTokenResponse> {
  return await fetch(tokenEndpoint, {
    method: 'POST',
    body: formData
  }).then(async (response: Response) => {
    if (!response.ok) {
      console.error(response)
      response.json().then((body) => {
        console.error(body)
      }).catch()
      throw Error(response.statusText)
    }
    return await response.json().then((body: TAzureADErrorResponse | TTokenResponse | TRevokeResponse): TTokenResponse | any => {
      if (isTokenResponse(body)) {
        return body
      } else if (isRevokeResponse(body)) {
        return null
      } else {
        console.error(body)
        throw Error(body['error_description'])
      }
    })
  })
}

export const fetchTokens = async (config: TInternalConfig): Promise<TTokenResponse> => {
  /*
    The browser has been redirected from the authentication endpoint with
    a 'code' url parameter.
    This code will now be exchanged for Access- and Refresh Tokens.
  */
  const urlParams = new URLSearchParams(window.location.search)
  const authCode = urlParams.get('code')
  const codeVerifier = window.localStorage.getItem(codeVerifierStorageKey)

  if (!authCode) {
    throw Error("Parameter 'code' not found in URL. \nHas authentication taken place?")
  }
  if (!codeVerifier) {
    throw Error("Can't get tokens without the CodeVerifier. \nHas authentication taken place?")
  }

  const formData = new FormData()
  formData.append('grant_type', 'authorization_code')
  formData.append('code', authCode)
  formData.append('scope', config.scope)
  formData.append('client_id', config.clientId)
  formData.append('redirect_uri', config.redirectUri)
  formData.append('code_verifier', codeVerifier)

  return await postWithFormData(config.tokenEndpoint, formData)
}

export const fetchWithRefreshToken = async (props: {
  config: TInternalConfig
  refreshToken: string
}): Promise<TTokenResponse> => {
  const { config, refreshToken } = props
  const formData = new FormData()
  formData.append('grant_type', 'refresh_token')
  formData.append('refresh_token', refreshToken)
  formData.append('scope', config.scope)
  formData.append('client_id', config.clientId)
  formData.append('redirect_uri', config.redirectUri)

  return await postWithFormData(config.tokenEndpoint, formData)
}

export const postRevokeToken = async (props: {
  config: TInternalConfig
  refreshToken: string | undefined
  token: string | undefined
}): Promise<void> => {
  const { config, refreshToken, token } = props
  const formData = new FormData()
  formData.append('client_id', config.clientId)
  if (refreshToken) {
    formData.append('refresh_token', refreshToken)
  }
  if (token) {
    formData.append('token', token)
  }

  if ((token || refreshToken) && config.revokeEndpoint) {
    await postWithFormData(config.revokeEndpoint, formData)
  }
}

// Returns epoch time (in seconds) for when the token will expire
export const epochAtSecondsFromNow = (secondsFromNow: number): number => Math.round(Date.now() / 1000 + secondsFromNow)

/**
 * Check if the Access Token has expired.
 * Will return True if the token has expired, OR there is less than 5min until it expires.
 */
export function epochTimeIsPast (timestamp: number): boolean {
  const now = Math.round(Date.now()) / 1000
  const nowWithBuffer = now + 120
  return nowWithBuffer >= timestamp
}

import { TInternalConfig } from './Types'

function stringIsUnset (value: any): boolean {
  const unset = ['', undefined, null]
  return unset.includes(value)
}

export function validateAuthConfig (config: TInternalConfig): void {
  try {
    if (stringIsUnset(config?.clientId)) { throw Error("'clientId' must be set in the 'AuthConfig' object passed to AuthProvider") }
    if (stringIsUnset(config?.authorizationEndpoint)) {
      throw Error(
        "'authorizationEndpoint' must be set in the 'AuthConfig' object passed to AuthProvider"
      )
    }
    if (stringIsUnset(config?.tokenEndpoint)) {
      throw Error(
        "'tokenEndpoint' must be set in the 'AuthConfig' object passed to AuthProvider"
      )
    }
    if (stringIsUnset(config?.redirectUri)) { throw Error("'redirectUri' must be set in the 'AuthConfig' object passed to AuthProvider") }
    if (stringIsUnset(config?.scope)) { throw Error("'scope' must be set in the 'AuthConfig' object passed to AuthProvider") }
  } catch (error) {
    if (error instanceof Error) {
      console.error(error.message)
    } else {
      console.error('Unexpected error', error)
    }
    console.error('Invalid AuthConfig')
  }
}
